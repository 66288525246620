





























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, User, Global } from '@/store';
import { i18n } from '../main';
import cloneDeep from 'lodash/cloneDeep';
import draggable from 'vuedraggable';
import PropUtils from '@/modules/PropUtils';
import { Watch } from 'vue-property-decorator';

@Component({
    components: {
        draggable
    }
})

export default class ProjectContacts extends Vue {
    @Props.State('list') projectProperties;
    @Props.Action('editProp') editProp;
    @User.State('project') project;
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;

    isLoading = false;
    projectContactsProp = [];
    projectContacts = [];
    contactDialog = false;
    columnDialog = false;
    editedIndex = -1;
    editedItem = {};
    defaultItem = {};
    newColumn = {
        name: ''
    };
    headers = [];
    headersProp = [];

    mounted() {
        this.setPageTitle('Project Contacts');
        this.generatePageData();
    }

    @Watch('projectProperties')
    generatePageData(){
        this.isLoading = true;
        const contacts = PropUtils.getProperty(this.projectProperties, 'meta.project_contacts');
        this.projectContactsProp = contacts ? contacts.value : [];
        const headers = PropUtils.getProperty(this.projectProperties, 'meta.project_contacts_headers');
        this.headersProp = headers 
            ? headers.value 
            : [{text: 'Name', value: 'name'}, {text: 'Email', value: 'email'}, {text: 'Role', value: 'role'}, {text: 'Phone', value: 'phone'}];
        this.reset();
        this.isLoading = false;
    }

    get formTitle() {
        return this.editedIndex === -1 ? i18n.t('Add New Contact') : i18n.t('Edit Contact');
    }

    editContact(item) {
        this.editedIndex = this.projectContacts.indexOf(item);
        this.editedItem = {...item};
        this.contactDialog = true;
    }

    deleteContact(item) {
        const index = this.projectContacts.indexOf(item);
        this.projectContacts.splice(index, 1);
    }

    saveContact() {
        if (this.editedIndex > -1) {
            this.projectContacts[this.editedIndex] = {...this.editedItem};
        }
        else {
          this.projectContacts.push(this.editedItem);
        }
        this.close();
    }

    cancelContact() {
        this.close();
    }

    close() {
        this.contactDialog = false;
        setTimeout(() => {
            this.editedItem = {...this.defaultItem};
            this.editedIndex = -1;
        }, 300);
    }

    saveColumn() {
        this.headers.push({ text: this.newColumn.name, value: this.newColumn.name.toLocaleLowerCase() });
        this.editedItem = {...this.editedItem, [this.newColumn.name.toLocaleLowerCase()]: ''};
        this.defaultItem = {...this.defaultItem, [this.newColumn.name.toLocaleLowerCase()]: ''};
        this.columnDialog = false;
        this.newColumn = {
            name: ''
        };
    }

    cancelColumn() {
        this.columnDialog = false;
        this.newColumn = {
            name: ''
        };
    }

    // deleteColumn(item) {
    //     const index = this.headers.indexOf(item);
    //     this.headers.splice(index, 1);
    //     this.projectContacts.forEach((contact) => {
    //         delete contact[item.value];
    //     });
    //     delete this.editedItem[item.value];
    //     delete this.defaultItem[item.value];
    // }

    saveToProp() {
        this.projectContactsProp = cloneDeep(this.projectContacts);
        this.headersProp = cloneDeep(this.headers);
        this.editProp({
            _notify_msg: this.$t('meta.project_contacts property is updated'),
            name: 'meta.project_contacts',
            objectID: this.project.id,
            objectType: 'projects',
            value: this.projectContactsProp
        });
        this.editProp({
            _notify_msg: this.$t('meta.project_contacts_headers property is updated'),
            name: 'meta.project_contacts_headers',
            objectID: this.project.id,
            objectType: 'projects',
            value: this.headersProp
        });
    }

    reset() {
        this.projectContacts = cloneDeep(this.projectContactsProp);
        this.headers = cloneDeep(this.headersProp);
    }
}
